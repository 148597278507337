import React, { useEffect, useState } from "react";
import axios from "../../node_modules/axios/index";
import LoadingBox from "../components/LoadingBox";
import CreatorList from "../components/CreatorList";
import Helmet from "../../node_modules/react-helmet/lib/Helmet";

export default function ListsScreen(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [toggle, setToggle] = useState("latest");
  const [creatorLists, setCreatorLists] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const mostViewedCreatorListIds = [
      "6703eb7f79a9d6006bc93870",
      "669c1bee245d360063c24554",
      "66c6b23a67408f0068f3bd6f",
      "6605eb28a812d6004163a91b",
      "66006d96cde1a10042edfaf0",
      "65fc3fce284ea2004121f454",
      "65fb53df72c031e75f56d79f",
      "6611b74536b13500418b12f3",
      "65fd8f3198555c0041a30c13",
      "65fc03da95dda40041e4366d",
      "66642588f4331a005b662dab",
      "669c1bee245d360063c24554",
      "66eae341329c63006b8f703d",
    ];

    const topContributorCreatorListIds = [
      "66eae341329c63006b8f703d",
      "6605eb28a812d6004163a91b",
      "6605efe9a812d6004163af55",
      "6602e7e0a812d600416341c3",
      "65fd8df698555c0041a30af6",
      "660e809036b135004189841e",
      "660e809036b135004189841e",
      "663b8196433b7b00604f59dd",
      "666db28ebca28200654446bc",
      "665b62933202af006a0af885",
      "66a3b28dcab7570064845d90",
      "66b34da0b22930006a838701",
    ];

    const fetchData = async (channelIds) => {
      setLoading(true);

      const data = {
        channelIds,
      };
      try {
        const response = await axios.post(
          process.env.REACT_APP_API_ADDRESS + "creatorlists/getcreatorlists",
          data
        );

        setCreatorLists(response.data);
        setLoading(false);

        return response.data; // Return the fetched data
      } catch (error) {
        console.error(
          "Error fetching creator lists:",
          error.response ? error.response.data : error.message
        );
        setLoading(false);

        throw error; // Rethrow or handle error as needed
      }
    };

    const fetchDataLatest = async () => {
      setLoading(true);

      try {
        const response = await axios.post(
          process.env.REACT_APP_API_ADDRESS + "creatorlists/latestcreatorlists"
        );

        setCreatorLists(response.data);
        setLoading(false);

        return response.data; // Return the fetched data
      } catch (error) {
        console.error(
          "Error fetching creator lists:",
          error.response ? error.response.data : error.message
        );
        setLoading(false);

        throw error; // Rethrow or handle error as needed
      }
    };

    if (toggle == "latest") {
      fetchDataLatest();
    }

    if (toggle == "mostViewed") {
      fetchData(mostViewedCreatorListIds);
    }

    if (toggle == "topContributors") {
      fetchData(topContributorCreatorListIds);
    }
  }, [toggle]);

  return (
    <div>
      <Helmet>
        <title>Discover the best YouTube channel lists</title>
        <meta
          name="description"
          content="Find and create YouTube channel lists based on your interests."
        ></meta>

        <link rel="canonical" href="https://www.favoree.io/lists"></link>
      </Helmet>
      <div className="cover pinkCover" id="headerHP">
        <div className="coverInside">
          <div className="oneThird">
            <img
              className="creatorListPicture"
              src="../images/channelListCards.png"
              alt="The best channel for every topic"
            ></img>
          </div>
          <div
            className="twoThird"
            style={{
              "max-width": "45rem",
            }}
          >
            <h1>YouTube channel lists</h1>
            <p
              style={{
                "max-width": "100%",
              }}
            >
              Share your favorite creators with the world!{" "}
            </p>
            <div className="space"></div>
            <div className="space"></div>
            <a className="buttonLightPink" href="/create-list">
              Create new list
            </a>
          </div>
        </div>
      </div>
      <div className="space"></div>
      <div className="space"></div>
      <div className="mainElement" id="listsPage">
        {creatorLists && (
          <div className="alignHorizontal" id="filterCentered">
            <div className="filterSlider">
              <ul className="ulFilter">
                {" "}
                <button
                  className="sortVideos"
                  id={toggle == "latest" ? "sortVideosOn" : "other"}
                  onClick={() => {
                    setToggle("latest");
                  }}
                >
                  <p>
                    {" "}
                    <i className="fa-solid fa-star"></i> Latest lists{" "}
                  </p>
                </button>
                <button
                  className="sortVideos"
                  id={toggle == "mostViewed" ? "sortVideosOn" : "other"}
                  onClick={() => {
                    setToggle("mostViewed");
                  }}
                >
                  <p>
                    {" "}
                    <i className="fa-solid fa-heart"></i> Most viewed lists{" "}
                  </p>
                </button>
                <button
                  className="sortVideos"
                  id={toggle == "topContributors" ? "sortVideosOn" : "other"}
                  onClick={() => {
                    setToggle("topContributors");
                  }}
                >
                  <p>
                    {" "}
                    <i class="fa-solid fa-medal"></i> Lists from top
                    contributors{" "}
                  </p>
                </button>
              </ul>
            </div>
          </div>
        )}
        <div className="space"></div>

        <div className="row top">
          {creatorLists?.length > 0 && !loading ? (
            creatorLists.map((c) => (
              <div id={c._id}>
                <CreatorList
                  name={c.listName}
                  list={c.list}
                  description={c.description}
                  uid={c.uid}
                  _id={c._id}
                ></CreatorList>
              </div>
            ))
          ) : (
            <div id="leftAlign">
              <LoadingBox></LoadingBox>
              <div className="space"></div>
              <div className="space"></div>
              <div className="space"></div>
              <div className="space"></div>
              <div className="space"></div>
              <div className="space"></div>
              <div className="space"></div>
              <div className="space"></div>
              <div className="space"></div>
              <div className="space"></div>
              <div className="space"></div>
              <div className="space"></div>
              <div className="space"></div>
              <div className="space"></div>
              <div className="space"></div>
              <div className="space"></div>
              <div className="space"></div>
              <div className="space"></div>
            </div>
          )}
        </div>
        <div className="space"></div>
        <div className="space"></div>
      </div>
    </div>
  );
}
